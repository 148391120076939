body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}

.navbar h1 {
  color: #f1356d;
}
.navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  /*color: #48C2F9;*/
  color: #386abb;
}

.pagination {


}


.pagination h1 {
  color: #f1356d;
}
.pagination .links {
  margin-left: auto;
  padding-left: 0;
  text-decoration: none;
}
.pagination a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
  /*margin: 6px;*/
  /*padding-left: 0;*/

}
.pagination a:hover {
  color: #48C2F9;
}


.swapi {
  padding-left: 20px;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  /*border-bottom: 1px solid #f2f2f2;*/
}


.content {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}

.starship-preview {
  padding: 10px 16px;
  margin: 20px 0;
  border-bottom: 1px solid #fafafa;
}


.home h2 {
  font-size: 20px;
  /*color: #48C2F9;*/
  color: #386abb;
  margin-bottom: 8px;
  padding-left: 20px;
  text-decoration: none;
}

.home p {
  font-size: 16px;
  color: #282c34;
  margin-bottom: 8px;
  padding-left: 20px;
  text-decoration: none;
}

.error-page {
  padding: 0 20px;
  color: #f1356d;
  text-decoration: none;
  font-size: 20px;
}

.error-page h2 {
  font-size: 30px;
  color: #48C2F9;
  margin-bottom: 8px;
  padding-left: 20px;
  text-decoration: none;
}

.error-page p {
  font-size: 20px;
  color: #282c34;
  margin-bottom: 8px;
  padding-left: 20px;
  text-decoration: none;
}

.pagination-buttons {
  padding: 0 20px;
}

.error-message {
  padding: 20px;
  color: red;
  font-weight: bold;
  font-size: 30px;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;
  /*background-color: #282c34;*/
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}
